// src/context/LanguageContext.js
import React, { createContext, useState, useEffect } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('hr'); // Default language is Croatian

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'hr';
    setLanguage(savedLanguage);
  }, []);

  const switchLanguage = () => {
    const newLanguage = language === 'hr' ? 'en' : 'hr';
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
